<template>
  <b-container fluid :style="{marginTop:'150px'}">
    <div :style="{width:'100%'}">
      <v-card
        class="mx-auto my-12 pa-5"
        max-width="450">
            <b-row class="d-flex justify-content-center">
              <b-col md="12">
                <img alt="Bufu logo" src="../assets/bufu-logo-admin.png" width="250">
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <b-col md="12" >
                <b-form @submit="onSubmit">
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input 
                        id="input-email"
                        v-model="form.email"
                        type="text"
                        required
                        placeholder="Masukan Email"></b-form-input>

                      <b-input-group-append>
                        <b-input-group-text>
                          <Iconify :icon="icons.email" :style="{color: '#575859'}" />
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col md="12">
                    <h1 :class="`text-subtitle-1 font-weight-light mb-n1`" style="text-align:right" @click="showPass = !showPass">{{showPass ? 'sembunyikan':'tampilkan'}}</h1>
                    <b-input-group>
                      <b-form-input 
                        id="input-password"
                        v-model="form.password"
                        :type="showPass ? 'text':'password'"
                        required
                        placeholder="Masukan Kata Sandi"></b-form-input>
                      <b-input-group-append>
                        <b-input-group-text>
                          <Iconify :icon="icons.lock" :style="{color: '#575859'}" />
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                <v-btn type="submit" color="primary" dark width="200" class="mt-5">
                  <span v-show="!isLoad">Masuk</span>
                  <b-spinner v-show="isLoad" small></b-spinner>
                </v-btn>
              <p v-if="message !== null" style="color:red; margin-top:20px">{{ message }}</p>
              </b-form>
              </b-col>
            </b-row>
      </v-card>
        <img alt="Bufu logo" src="../assets/bufu-logo-trans.png" width="150">
      <br />
        <small>&#169; {{ new Date().getFullYear() }} Koperasi Bina Umati Fadhal Umar</small>
      
    </div>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </b-container>
</template>

<script>
import { mapActions } from "vuex";

import {Iconify, email, lock} from '@/lib/icons.js' 

import OverlayProgress from '@/components/OverlayProgress.vue'


export default {
  name: 'Login',
  components: {
    Iconify,
    OverlayProgress
  },
  data() {
    return {
      icons: {
        email,
        lock
      },
      form: {
        email: '',
        password: ''
      },
      message: null,
      isLoad: false,
      showPass: false,
    }
  },
  watch: {
    'form.email': function() {
        this.message = null
    },
    'form.password': function() {
        this.message = null
    }
  },
  methods: {
    ...mapActions(["LogIn"]),
    async onSubmit(evt) {
      this.isLoad = true
      evt.preventDefault()

      const User = new FormData();
      User.append("username", this.form.email);
      User.append("password", this.form.password);
      
      await this.LogIn(User).then(res => {
        if(res.status === 200) {
            this.$router.push("/");
        }else{
            this.message = res.message
        }
      }).catch(err => {
        console.log(err)
      })
      this.isLoad = false
    },
  },
  mounted() {
    this.$nextTick(function () {this.$root.overlay = false})
  }
}
</script>

<style scoped>
p{
  font-size: 12px
}
.v-application .text-subtitle-1{
  font-size: 12px !important;
  cursor: pointer;
}
</style>