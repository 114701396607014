<template>
    <v-overlay 
        :value="overlay"
        :style="{padding:'100px'}"
        light
        color="#fff"
        opacity=1>
        <!-- display loading progress -->
        <img v-if="typeof isError !== 'string' && !isError" alt="Bufu logo" src="../assets/bufu-logo-load.png" width="200">
            <v-progress-linear
            v-if="typeof isError !== 'string' && !isError"
            indeterminate
            color="indigo"
            ></v-progress-linear>

        <!-- display error server -->
        <template v-if="typeof isError !== 'string' && isError">
            <img alt="Bufu logo" src="../assets/error-code.png" width="350" >
            <h1>Oops!</h1>
            <h4>KESALAHAN PADA SERVER</h4>
            <p>Mohon maaf, saat ini server sedang ada masalah. Silahkan refresh halaman atau coba lagi nanti</p>
        </template>

        <!-- display error connection -->
        <template v-if="isError === this.$root.message.result.errConnection">
            <img alt="Bufu logo" src="../assets/error-conn.png" width="350" >
            <h1>Oops!</h1>
            <h4>TIDAK ADA KONEKSI INTERNET</h4>
            <p>Periksa koneksi jaringan anda. Silahkan refresh halaman atau coba lagi nanti</p>
        </template>
        
        <b-col md="12" :style="{ display:'flex', alignItems:'center', justifyContent:'center', marginTop:'200px'}" class="d-none d-sm-block">
            <img v-if="isError" alt="Bufu logo" src="../assets/bufu-logo-load.png" width="200"><br/>
        </b-col>
    </v-overlay>
</template>
<script>
export default {
    props: ['overlay', 'isError']
}
</script>
<style scoped>
p, h1, h4{
    color: #7f7f7f;
}
</style>